export default {
  path: '/sso/permission-assignment',
  title: '权限配置',
  children: [// {
  //   path: '/sso/permission-assignment/role-management',
  //   title: '角色管理',
  // },
  {
    path: '/sso/permission-assignment/permission-management',
    title: '功能权限管理',
    children: [{
      path: '/sso/permission-assignment/permissionDetail',
      title: '角色权限设置',
      hide: true
    }]
  }, {
    path: '/sso/permission-assignment/data-permission',
    title: '数据权限管理'
  }, {
    path: '/sso/permission-assignment/data-sharing',
    title: '数据开放权限',
    children: [{
      path: '/sso/permission-assignment/data-sharing/details',
      title: '数据开放权限详情',
      hide: true
    }]
  }, {
    path: '/sso/permission-assignment/special-permission',
    title: '特殊页面权限'
  }]
};