import dayjs from 'dayjs';
export default {
  name: 'i-copyright',
  data: function data() {
    return {
      links: [],
      copyright: ''
    };
  },
  created: function created() {
    var year = dayjs().format('YYYY');
    this.copyright = "Copyright \xA9 2019-".concat(year, " \u98DE\u672A\u4E91\u79D1\uFF08\u6DF1\u5733\uFF09\u6280\u672F\u6709\u9650\u516C\u53F8");
  }
};